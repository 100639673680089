import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"


import Layout from "../components/layout"
import SEO from "../components/seo"
import { BlogBody } from "../components/bodyContentBlog"

class AllContentTypesContentfulTemplate extends React.Component {
  render() {      
    const post = this.props.data.contentfulAllContentTypes
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.title}
          description={post.description || post.excerpt}
        />
    
        <div style={{
            backgroundColor: `${
              post.backgroundColour
            }`, color: `${
              post.textColour
            }`}}>
          {post.image && (
              <div className="post-content-image">
                <Img
                  fluid={post.image.fluid}
                  alt={post.title}
                  style={{position: "relative", width: "100%", height: "70vh"}}
                />
              </div>
            )}

          <article
            className={`post-content ${`with-image` || `no-image`}`}
          >
            <header className="post-content-header">
              <h1 className="post-content-title">{post.title}</h1>
              <div className="postDetails"><a style={{color: `${
              post.textColour
            }`}} href={`/${post.category}`}>{post.category}</a></div>
              <div className="postDetails"><span className="date">{post.publishedDate}</span></div>
              <div className="postDetails">Publicado por <a style={{color: `${
              post.textColour
            }`}} href={`/${post.author}`}>{post.author}</a></div>
            </header>
            
            {post.description && (
              <p class="post-content-excerpt">{post.description}</p>
            )}

            <BlogBody content={post.bodyContent}/>

            
            <footer className="post-content-footer">
            
            </footer>
          </article>
        </div>
      </Layout>
    )
  }
}

export default AllContentTypesContentfulTemplate

export const pageQuery = graphql`
  query ContentfulAllContentTypesBySlug($slug: String!){
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulAllContentTypes( slug: { eq: $slug } ){
      title
      description
      category
      backgroundColour
      textColour
      publishedDate(formatString: "DD/MM/YY")
      author
      image{
        fluid(maxWidth: 2048, quality: 100){
          src
          }
      }
      bodyContent {
        raw
        references{
          ... on ContentfulAsset {
            __typename
           contentful_id
           title
           fluid(quality: 100){
               src
            }
          }
          ... on ContentfulEmbeddedEntry {
            contentful_id
            backgroundColour
            textColour
            bodyContent {
              raw
              references{
                ... on ContentfulAsset {
                  __typename
                 contentful_id
                 title
                 fluid(quality: 100){
                     src
                  }
                }
              }

            }
          }

        }
      }
    }
  }
  
`
